import React, {useEffect, useReducer, useMemo} from "react";
import { MDBModal, MDBModalHeader
       , MDBModalBody, MDBModalFooter
       , MDBCard, MDBRow, MDBCol, MDBBtn }     from "mdbreact";
import { FormattedMessage, useIntl }           from "react-intl";
import { useSelector, useDispatch }            from "react-redux";
import * as R                                  from "ramda";

import { ON_DUE }                              from "../../../../constants/autopay";
import { useValidator }                        from "../../../../utils/validation";
import { getClientConfig }                     from "../../../../utils/config-utils";
import {
    isNonCCPaymentMethod
    , accountSupportsDebitOnly
    , isSepa
    , isRealTimeOnlyCard, sortWallet
} from "../../../../utils/payment-utils";
import { getAutopayAccounts, addSchedule
       , editSchedule, getLatestAccount }      from "../../../../api/autopay-api";
import {getSortedWalletPMs}                    from "../../../../api/wallet-api";
import ReactPortalModal                        from "../../../ReactPortalModal";
import Error                                   from "../../../Error";
import Spinner                                 from '../../../Spinner';
import ProgressBar                             from "../../ProgressBar";
import AddPaymentMethodModal                   from "../AddPaymentMethodModal";

import AccountSelection                        from "./AccountSelection";
import PaymentMethodInfo                       from "./PaymentMethodInfo";
import PaymentSettings                         from "./PaymentSettings";
import SepaMandate                             from "../../SepaMandate";
import Confirmation                            from "./Confirmation";
import ThreeDSForm                             from "../../3dSecureForm";
import MDBBtnWrapper                           from "../../../MDBFix/MDBBtnWrapper";
import { complete3ds }                         from '../../../../actions/config-action';

const STEPS =
  [ { id: "acc"
    , label: "modal.view-schedule.subtitle.account-information"
    , btn2: "ups.btn.next.label"
    , btn3: "ups.btn.cancel.label"
    , an_label : "account-information"
    }
  , { id: "pm"
    , label: "modal.view-schedule.subtitle.payment-method-information"
    , btn1: "ups.btn.back.label"
    , btn2: "ups.btn.next.label"
    , btn3: "ups.btn.cancel.label"
    , an_label : "payment-method-information"
    }
  , { id: "sch"
    , label: "modal.view-schedule.subtitle.schedule-information"
    , btn1: "ups.btn.back.label"
    , btn2: "ups.btn.submit.label"
    , btn3: "ups.btn.cancel.label"
    , an_label : "payment-settings"
    , submitOnNext: true
    }
  , { id: "conf"
    , label: "modal.view-schedule.subtitle.confirmation"
    , btn2: "close.label"
    , an_label : "confirmation"
    }
  ];

const SEPA_STEP =
  { id: "sepa"
  , label: "sepa-mandate.card-header.title"
  , btn1: "ups.btn.back.label"
  , btn2: "ups.btn.submit.label"
  , btn3: "ups.btn.cancel.label"
  , submitOnNext: true
  , an_label : "sepa_madate"
  }

const INITIAL_SCHEDULE =
    { type: ON_DUE
    , payBeforeDueDate: ""
    , scheduleDay: "2"
    , amount: null
    , expiryDate: null
    , account: null
    , paymentMethod: null
    , authorizeSchedule: false
    , authorizeDD: false
    };

const INITIAL_STATE =
  { step: 0 // Modal step (0-indexed)
  , steps: STEPS
  , errors: []
  , schedule: INITIAL_SCHEDULE // Active schedule being built
  , accounts: null // Bound to step 1
  , wallet: [] // Bound to step 2
  , walletModal: false // Step 2's sister modal
  , confirmation: {} // Bound to step 4
  , fetchingWallet: false // Wallet to use for new/updated schedule.
  , fetchingAccounts: false // Accounts for new schedule selection.
  , fetchingAccount: false // Refreshed account with ccEligible when updating a schedule.
  , submitting: false // Create or update an account.
  , threeDSecureFormData: {}
  };

const stepLens = R.lensProp('step');
const stepsLens = R.lensProp('steps');
const errorsLens = R.lensProp('errors');
const fetchingWalletLens = R.lensProp('fetchingWallet');
const fetchingAccountsLens = R.lensProp('fetchingAccounts');
const fetchingAccountLens = R.lensProp('fetchingAccount');
const submittingLens = R.lensProp('submitting');
const walletLens = R.lensProp('wallet');
const schedulePayMethodLens = R.lensPath(['schedule', 'paymentMethod']);
const scheduleAccountLens = R.lensPath(['schedule', 'account']);
const scheduleAccountIdLens = R.lensPath(['schedule', 'account', 'id']);
const scheduleAccountCreditAllowedLens = R.lensPath(['schedule', 'account', 'creditAllowed']);
const walletModalLens = R.lensProp('walletModal');
const sepaMandateLens = R.lensPath(['schedule', 'sepaMandate']);

const stateErrorClear = R.set(errorsLens, []);
const stateStepForward = R.over(stepLens, R.inc);
const stateStepBack = R.over(stepLens, R.dec);

const updateScheduleStepWithSepaFlow = R.over
  ( R.lensIndex (2), step => ({ ...step
                              , submitOnNext: false
                              , btn2: "ups.btn.next.label"
                              })
  );

const isCreateSchedule = R.pathSatisfies(R.isNil, ['schedule', 'scheduleId']);
const isScheduleEdit = R.complement(isCreateSchedule);

const evaluateSteps = R.compose
  ( R.when (isScheduleEdit, R.over (stepsLens, R.tail))
  , R.ifElse
      ( R.allPass([ R.propEq('enableSepaMandate', true)
                  , R.pathSatisfies(isSepa, ['schedule', 'paymentMethod'])
                  ])
      , R.compose
          ( R.over (stepsLens, updateScheduleStepWithSepaFlow)
          , R.set (stepsLens, R.insert(3, SEPA_STEP) (STEPS))
          )
      , R.set (stepsLens, STEPS)
      )
  );

const isFetching = R.anyPass
  ([ R.view(fetchingAccountsLens)
   , R.view(fetchingWalletLens)
   , R.view(submittingLens)
   , R.view(fetchingAccountLens)
  ]);

const is3dSecureFlow = R.has('hostedCheckout');

const buildInitialState = ({enableSepaMandate, initialSchedule}) => R.compose
  ( evaluateSteps
  , state => ({ ...state
              , schedule: {...INITIAL_SCHEDULE, ...initialSchedule}
              , enableSepaMandate
              , steps: R.isEmpty(initialSchedule) ? state.steps : R.tail (state.steps)
              , fetchingAccount: !R.isEmpty(initialSchedule)
              , fetchingAccounts: R.isEmpty(initialSchedule)
              })
  );

const filterCreditPayMethods = R.filter(isNonCCPaymentMethod);

const updateEligibleWallet = R.when
  ( R.compose(accountSupportsDebitOnly, R.view(scheduleAccountLens))
  , R.over(walletLens, filterCreditPayMethods)
  );

const defaultWallet = R.propEq('default', true);

const selectDefaultWallet = R.ifElse
  ( R.any(defaultWallet)
  , R.find(defaultWallet)
  , R.head
  );

const loadPayMethodFromWallet = state => R.set(
    schedulePayMethodLens,
    selectDefaultWallet(state.wallet)
) (state);

// State action functions
const stateActionSubmitted = ({confirmation}) => R.compose
    ( R.over(R.lensProp('confirmation'), c => confirmation ?? c)
    , stateStepForward
    , stateErrorClear
    , R.set(submittingLens, false)
    );

const stateActionFetchFailed = ({error, fetchType}) => R.compose
    ( R.over(errorsLens, R.append (error))
    , state => {
          switch (fetchType) {
            case 'accounts': return R.set(fetchingAccountsLens, false, state);
            case 'account':  return R.set(fetchingAccountLens, false, state);
            case 'wallet':   return R.set(fetchingWalletLens, false, state);
            case 'submit':   return R.set(submittingLens, false, state);
            default: return state;
          }
      }
    );

const stateActionGoNext = R.when
    ( R.propSatisfies(errors => errors.length === 0, 'errors') // block
    , stateStepForward
    );

const stateActionGoPrev = R.compose
    ( stateErrorClear
    , R.when
        ( R.propSatisfies( n => n > 0, 'step')
        , stateStepBack
        )
    );

const stateActionReceiveWallet = ({wallet, merchant}) => R.compose
    ( evaluateSteps
    , R.when(isCreateSchedule, loadPayMethodFromWallet)
    , updateEligibleWallet
    , R.over(walletLens, R.filter(R.complement(isRealTimeOnlyCard(merchant))))
    , R.set(fetchingWalletLens, false)
    , R.set(walletLens, wallet)
    );

const stateActionReceiveAccounts = ({accounts}) => R.compose
    ( R.set(fetchingAccountsLens, false)
    , R.set(R.lensProp('accounts'), accounts)
    );

const stateActionFetchWallet = R.when
    ( R.compose
        ( R.complement (R.isNil)
        , R.view(scheduleAccountLens)
        )
    , R.set(fetchingWalletLens, true)
    );

const stateActionSubmit = R.compose
    ( stateErrorClear
    , R.set(submittingLens, true)
    );

const stateActionSelectAccount = ({account}) => R.compose
    ( stateActionFetchWallet
    , R.set(scheduleAccountLens, account)
    , R.set(fetchingAccountLens, false)
    );

const stateActionToggleWallet = R.when
  ( R.propSatisfies(R.isEmpty, 'threeDSecureFormData') // Don't toggle modal when we are setting up 3ds
  , R.over(walletModalLens, R.not)
  );

const stateUpdateSchedule = ({schedule}) => R.compose
  ( evaluateSteps
  , R.set(R.lensProp('schedule'), schedule)
  );

  /**
   * autopay controller returns generic errorCode in errorCode field, 
   * but also the error body, if errorBody errorCode matches any in this list then use that errorCode instead
   */
const overrideErrorCode = R.anyPass([R.propEq('errorCode', 'schedule.validation.duplicate')])

function reducer(state, action) {
    switch (action.type) {
        case 'init':             return buildInitialState (action) (state)
        case 'fetch_wallet':     return stateActionFetchWallet (state);
        case 'receive_wallet':   return stateActionReceiveWallet (action) (state);
        case 'receive_accounts': return stateActionReceiveAccounts (action) (state);
        case 'receive_account':  return stateActionSelectAccount (action) (state);
        case 'submit':           return stateActionSubmit (state)
        case 'submitted':        return stateActionSubmitted (action) (state);
        case 'fetchFailed':      return stateActionFetchFailed (action) (state);
        case 'goNext':           return stateActionGoNext (state);
        case 'goPrev':           return stateActionGoPrev (state);
        case 'selectAccount':    return stateActionSelectAccount (action) (state);
        case 'updateSchedule':   return stateUpdateSchedule (action) (state);
        case 'toggleWallet':     return stateActionToggleWallet (state);
        case 'set_3ds_form':     return { ...state, threeDSecureFormData: action.formData };
        case 'sepaMandate':      return R.set(sepaMandateLens, action.sepaMandate) (state);
        case 'authorizeSepa':    return R.over(sepaMandateLens, sepaMandate => ({...sepaMandate, authorizeSepa: action.authorizeSepa})) (state);
        default:                 return state;
    }
}

const updateScheduleRequest = R.omit(['account']);

export default function AddScheduleModal(
    { modalId, isOpen, toggleModal, initialSchedule = {}, preSelectedAccount, preSelectedWallet
    , clearPreSelection, addWalletHandler, submit3DSForm
    }) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const rDispatch = useDispatch();
    const {selectedCountry: countryCode} = useSelector(s => s.auth.user);
    const {sessionSelection} = useSelector(s => s.config);
    const {merchant} = sessionSelection;
    const merchantId = merchant.id;
    const intl = useIntl();

    const enableSepaMandate = useMemo(() => getClientConfig(merchant).ENABLE_SEPA_MANDATE, [countryCode, merchant]);

    const showPrimaryModal = state.walletModal ? false : isOpen;

    const validator = useValidator();
    const {vState, vFields} = useSelector(s => s.validation);

    const currentStep = state.steps[state.step];

    const dispatchError = (error, fetchType) => dispatch({type: "fetchFailed", error, fetchType});

    useEffect(() => {
      dispatch({type: 'init', enableSepaMandate, initialSchedule});
      return () => rDispatch(complete3ds());
    }, []);

    useEffect(()=> {
        //skip selection of an account if we've pre-selected one (3D secure flow)
        if(preSelectedAccount && !state.fetchingAccounts && state?.accounts && currentStep.id === "acc" && !submit3DSForm){
            const account = state.accounts.find((a)=>(a.id === preSelectedAccount))
            if(account) {
                dispatch({type: "selectAccount", account})
                dispatch({type: "goNext"})
            }
        }
    }, [preSelectedAccount, state.fetchingAccounts]);

    useEffect(()=> {
        //auto-select wallet entry if we've pre-selected one (3D secure flow)
        if(preSelectedWallet && !state.fetchingWallet && state?.wallet && currentStep.id === "pm" && !submit3DSForm){
            const paymentMethod = state.wallet.find(pm => pm._id === preSelectedWallet);
            if(paymentMethod) dispatch({ type: 'updateSchedule', schedule: {...state.schedule, paymentMethod} })
            if(clearPreSelection) clearPreSelection()
        }
    }, [preSelectedWallet, state.fetchingWallet]);

    useEffect(() => {
        if (state.fetchingWallet) {
            getSortedWalletPMs(merchant.paymentMethodCategories)({intent: 'payment'})
                .then(wallet => dispatch({type: 'receive_wallet', wallet, merchant}))
                .catch(({errorCode}) => dispatchError(errorCode, 'wallet'));
        }
    }, [state.fetchingWallet]);

    useEffect(() => {
        if (state.fetchingAccounts) {
            getAutopayAccounts({countryCode})
                .then(accounts => dispatch({type: 'receive_accounts', accounts}))
                .catch(({errorCode}) => dispatchError(errorCode, 'accounts'));
        }
    }, [state.fetchingAccounts]);

    useEffect(() => {
        if (state.fetchingAccount) {
            getLatestAccount({ countryCode
                             , accountId: R.view(scheduleAccountIdLens, state)
                             })
                .then(accounts => dispatch({type: 'receive_account', account: R.head(accounts)}))
                .catch(({errorCode}) => dispatchError(errorCode, 'account'));
        }
    }, [state.fetchingAccount]);

    useEffect(() => {
      const submitSchedule = async () => {
          const confirmation = isCreateSchedule (state)
              ? await addSchedule({...state.schedule, merchantId})
              : await editSchedule({...updateScheduleRequest(state.schedule), merchantId});
          return confirmation;
      }
      if (state.submitting) {
          submitSchedule()
            .then(confirmation => dispatch({type: 'submitted', confirmation}))
            .catch(({errorCode, errorBody}) => dispatchError( overrideErrorCode(errorBody) ? errorBody.errorCode : errorCode, 'submit'));
      }
    }, [state.submitting]);

    const closeModal = () => toggleModal(modalId);
    const submit = () => dispatch({type: 'submit'});

    const nextStep = () => {
        const result = validator.validateAll();
        if (result.messages.length > 0) return;
        if (state.step >= state.steps.length - 1) return closeModal();
        if (currentStep.submitOnNext) submit();
        else dispatch({type: "goNext"});
    };

  const prevStep = () => dispatch({ type: "goPrev" });
  const modalHeading = R.isEmpty(initialSchedule) ? "schedule.btn.create-schedule" : "modal.view-schedule.btn.edit-schedule";
  const modalLabel = (R.isEmpty(initialSchedule) ? "add-schedule" : "edit-schedule") + `|${currentStep.an_label}`;

    return (
      <>
          {
            !R.isEmpty(state.threeDSecureFormData) &&
              <ThreeDSForm
                  httpmethod={state.threeDSecureFormData.httpMethod}
                  hostedurl={state.threeDSecureFormData.hostedUrl}
                  pareq={state.threeDSecureFormData.PaReq}
                  md={state.threeDSecureFormData.MD}
                  termurl={state.threeDSecureFormData.TermUrl}
                  submit={submit3DSForm}
              />
        }
        {showPrimaryModal &&
          <ReactPortalModal isOpen={showPrimaryModal} an_label={modalLabel}>
            <MDBModal isOpen={showPrimaryModal} toggle={closeModal} size="xl" disableBackdrop disableFocusTrap={false} labelledBy={intl.formatMessage({ id: modalHeading })}>
              {isFetching(state) && <Spinner isSpinning={true} />}
              <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({ id: "close.dialog.btn" }, { name: intl.formatMessage({ id: modalHeading }) })} toggle={closeModal}>
                <FormattedMessage id={modalHeading} />
              </MDBModalHeader>
              <MDBModalBody>
                <MDBCard className="mb-4">
                  <ProgressBar
                    stepNames={state.steps}
                    currentStep={state.step}
                    stepId={currentStep.id}
                    isShown
                  />
                </MDBCard>
                <Error
                  errors={state.errors}
                  vFields={vFields}
                  vState={vState}
                />
                {currentStep.id === "acc" && !preSelectedAccount && state.accounts && <AccountSelection
                  accountList={state.accounts}
                  schedule={state.schedule}
                  onChange={account => dispatch({ type: "selectAccount", account })}
                />}
                {currentStep.id === "pm" && <PaymentMethodInfo
                  wallet={state.wallet}
                  schedule={state.schedule}
                  onChange={schedule => dispatch({ type: 'updateSchedule', schedule })}
                  onAddPM={() => dispatch({ type: "toggleWallet" })}
                  authorizeDD= {state.schedule.authorizeDD}
                />}
                {currentStep.id === "sch" && <PaymentSettings
                  schedule={state.schedule}
                  onChange={schedule => dispatch({ type: 'updateSchedule', schedule })}
                  maxCurrencyDigits={merchant.maxCurrencyDigits}
                />}
                {currentStep.id === "sepa" && <SepaMandate
                  schedule={state.schedule}
                  onSepaMandateLoad={sepaMandate => dispatch({ type: 'sepaMandate', sepaMandate })}
                  onChange={authorizeSepa => dispatch({ type: 'authorizeSepa', authorizeSepa })}
                />}
                {currentStep.id === "conf" && <Confirmation
                  confirmation={state.confirmation}
                  isNew={isCreateSchedule(state)}
                />}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBRow>
                  <MDBCol size="12" className="modal-button-col">
                    {currentStep.btn1 &&
                      <MDBBtnWrapper label={intl.formatMessage({ id: currentStep.btn1 })} color="secondary" className="order-1" onClick={prevStep}>
                      </MDBBtnWrapper>
                    }
                    {currentStep.btn2 &&
                      <MDBBtnWrapper label={intl.formatMessage({ id: currentStep.btn2 })} color="primary" className="order-first" onClick={nextStep} >
                      </MDBBtnWrapper>
                    }
                  </MDBCol>
                </MDBRow>
                {currentStep.btn3 &&
                  <MDBRow>
                    <MDBCol size="12" className="modal-button-col">
                      <MDBBtnWrapper label={intl.formatMessage({ id: currentStep.btn3 })} color="cancel" className="order-last" onClick={closeModal}>
                      </MDBBtnWrapper>
                    </MDBCol>
                  </MDBRow>
                }
              </MDBModalFooter>
            </MDBModal>
          </ReactPortalModal>
        }

        {state.walletModal && <AddPaymentMethodModal
          isOpen={state.walletModal}
          toggleModal={() =>  dispatch({ type: 'toggleWallet' })}
          creditAllowed={R.view(scheduleAccountCreditAllowedLens, state)}
          walletSuccess={pm => {
              addWalletHandler(pm, state.schedule.account.id, state.schedule.id);
              if(is3dSecureFlow(pm)) {
                  dispatch({ type: 'set_3ds_form', formData: pm.hostedCheckout })
              } else {
                  dispatch({ type: 'fetch_wallet'})
              }
          }}
        />}
      </>
    );
}
