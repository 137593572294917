import React, { Component } from 'react';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import Exception from '../../Exception/index'
import {injectIntl, FormattedMessage} from 'react-intl';
import {MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem  } from "mdbreact";
import AddNewUserModal from "../../../components/UPS/Modal/AddNewUserModal";
import EditUserRoleModal from "../../../components/UPS/Modal/EditUserRoleModal";
import ManageUserAccessModal from '../../../components/UPS/Modal/ManageUserAccessModal';
import RemoveUserModal from '../Modal/RemoveUserModal';
import RemoveMultipleUserModal from '../Modal/RemoveMultipleUserModal';
import produce from "immer";
import {getSessionFilter, nameToString} from "../../../utils/utils";
import FilterModal from "../Modal/FilterModal/FilterModal";
import ManageUserFilterCriteria from "../Modal/FilterModal/FilterCriteria/ManageUserFilterCriteria";
import {FilterFuncMultiSelect} from "../Modal/FilterModal/FilterFuncs";
import FilteredDataTableV3, {INITIAL_TABLE_STATE} from "../../DataTableV3/DataTableV3FilterWrapper";

class ManageUserTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            addNewUserModal: false,
            removeUserModal: false,
            removeMultipleUserModal: false,
            editUserRoleModal: false,
            manageUserAccessModal: false,
            filterModal: false,
            tableState:{
                ...INITIAL_TABLE_STATE,
                filter: this.props.filter ??  {}
            }
        };
    }

    toggleModal = (modalName, id) => {
        let modalActive = !this.state[`${modalName}Modal`]
        this.setState({
            userToModify: modalActive ? id : undefined,
            [`${modalName}Modal`]: modalActive
        });
    };

    getDisplayFuncs = (intl) => ({
        getRole: ({role}) => intl.formatMessage({id: (`ups.manage-user.role.${role}.label`)}),
        getLastModifiedBy: ({externalModifiedByInfo}) => nameToString(externalModifiedByInfo?.firstName, externalModifiedByInfo?.lastName),
        getLastModifiedOn: ({externalModifiedOn}) => intl.formatDate(externalModifiedOn,
            {year:"numeric", month:"short", day:"numeric", hour:"numeric", minute:"2-digit", second:"2-digit", timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}
        ),
        getName: ({firstName, lastName})=>nameToString(firstName, lastName)
    })

    createDatatableCols = () => {
        const { intl } = this.props;
        const f = (id)=>intl.formatMessage({id: id})
        const {getRole, getLastModifiedBy, getLastModifiedOn, getName} = this.getDisplayFuncs(intl)
        return [
            {
                field: 'fullName',
                label: f('ups.manage-user-table.name'),
                display:getName,
                serialize:getName,
                sortFunc:getName,
                mobileCardPrimary: true
            },
            {
                field: 'role',
                label: f('ups.manage-user-table.role'),
                display:getRole,
                serialize:getRole
            },
            {
                field: 'externalModifiedOn',
                label: f('ups.manage-user-table.modified-date'),
                display: getLastModifiedOn,
                serialize: getLastModifiedOn
            },
            {
                field: 'lastModifiedBy',
                label: f('ups.manage-user-table.modified-by'),
                display: getLastModifiedBy,
                serialize: getLastModifiedBy,
                sortFunc: getLastModifiedBy
            },
            {
                field: 'actions',
                label: f('ups.manage-user-table.actions'),
                sortable: false,
                display: this.createDatatableRowActions
            }
        ]
    };

    createDatatableRowActions = (data) => {
        const {currentUser, intl} = this.props;
        let components = [];
        if (data.id === currentUser.id) return null
        let adminPermissions = {'modify':'user_admin_modify', 'delete':'user_admin_delete'}
        let basicPermissions = {'modify':'user_modify', 'delete':'user_delete'}
        let pNames = (data.role === "ADMINISTRATOR") ? adminPermissions : basicPermissions

        const actionLabel = intl.formatMessage({id:"more-actions.manage-user-label"}, {fullName: data.fullName});
        
        if(currentUser.permissions){
            if(currentUser.permissions[pNames['modify']] && data.role !== "ADMINISTRATOR"){
                components.push(
                    <MDBDropdownItem key={`dropdown-manage-access-${data.id}`} onClick={() => this.toggleModal('manageUserAccess', data.id)}>
                        {intl.formatMessage({ id: 'ups.manage-user-table.actions.btn-manage-access.label'})}
                    </MDBDropdownItem>
                );
            }

            if(currentUser.permissions[pNames['modify']]) {
                components.push(
                    <MDBDropdownItem key={`dropdown-edit-user-role-${data.id}`}
                                    onClick={() => this.toggleModal('editUserRole', data.id)}>
                        {intl.formatMessage({id: 'ups.manage-user-table.actions.btn-edit-role.label'})}
                    </MDBDropdownItem>
                );
            }

            if(currentUser.permissions[pNames['delete']]) {
                components.push(
                    <React.Fragment key={`dropdown-delete-user-${data.id}`}>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem onClick={() => this.toggleModal('removeUser', data.id)}>
                            {intl.formatMessage({ id: 'ups.manage-user-table.actions.btn-remove-user.label'})}
                        </MDBDropdownItem>
                    </React.Fragment>
                );
            }
        }
        if(components.length === 0) return null

        return (
            <MDBDropdown key={`manage-user-drop-down-${data.id}`}>
                <MDBDropdownToggle color="primary" className="custom-vertical-dropdown" aria-label={actionLabel}>
                    <i className="fas fa-ellipsis-v"></i>
                </MDBDropdownToggle>
                <MDBDropdownMenu basic right>
                    {components}
                </MDBDropdownMenu>
            </MDBDropdown>
        )
    };

    isUserSelectable(user){
        let {currentUser} = this.props
        let removeAdminCheck = (!currentUser.permissions['user_admin_delete'] && (user.role === "ADMINISTRATOR"))
        return (user.id !== currentUser.id && !removeAdminCheck && currentUser?.permissions['user_delete'])
    }

    filterFunc(data, columns){
        const {filter} = this.state.tableState;
        const {intl} = this.props;
        return FilterFuncMultiSelect(filter, 'role', data, columns, intl)
    }

    rowCheckBoxProps = (rowData) => {
        const {intl} = this.props;
        return {            
            ...(rowData ? {'aria-label': `${intl.formatMessage({id: "register.subtitle.user"})} ${rowData.fullName}`} : '')
        };
    }

    render() {
        const { toggleModal } = this;
        const { userToModify, error, tableState} = this.state;
        // modals
        const { addNewUserModal, removeUserModal, removeMultipleUserModal, editUserRoleModal, manageUserAccessModal, filterModal} = this.state;
        const {companyUserList, myAccounts, userCallbackActions, currentUser, intl, caption} = this.props;
        const {filter, selection} = tableState;
        let userToModifyObj = null
        if(userToModify) userToModifyObj = companyUserList.find((user)=>(user.id === userToModify))

        if (error instanceof TypeError) {
            return (<Exception error={error} />)
        } else {
            return (
                <>
                    <FilteredDataTableV3
                        caption={caption}
                        name='manage-users'
                        data={companyUserList}
                        columns={this.createDatatableCols()}
                        defaultSorting={'fullName'}
                        tableState={tableState}
                        tableStateAction={(action)=>this.setState(action)}
                        buttons={[
                            {text: <FormattedMessage id="ups.add-new-user.title"/>, action:()=>toggleModal('addNewUser')},
                            {type: 'secondary', hide: (selection.size === 0),text: <FormattedMessage id="ups.removeSelected"/>, action:()=>toggleModal('removeMultipleUser')},
                            {type: "filter", action:()=>toggleModal('filter')}
                        ]}
                        {...(currentUser?.permissions.user_delete && {
                            selectEnabled:true,
                            selectAll:true,
                            checkSelectable:(item)=>this.isUserSelectable(item)
                        })}
                        searchable
                        filterFunc={this.filterFunc.bind(this)}
                        mobileCard
                        rowCheckBoxProps={this.rowCheckBoxProps}
                        rowHeaderField ={'fullName'}
                    />
                    {addNewUserModal && (
                        <AddNewUserModal
                            isOpen={addNewUserModal}
                            toggleModal={toggleModal}
                            accounts={myAccounts}
                            addUserCallback={userCallbackActions.addUser}
                        />
                    )}
                    {removeUserModal && (
                        <RemoveUserModal
                            isOpen={removeUserModal}
                            data={userToModifyObj}
                            toggleModal={toggleModal}
                            deleteUserCallback={userCallbackActions.removeUser}
                        />
                    )}
                    {removeMultipleUserModal && (
                        <RemoveMultipleUserModal
                            isOpen={removeMultipleUserModal}
                            data={selection}
                            toggleModal={toggleModal}
                            deleteUserCallback={userCallbackActions.removeUser}
                        />
                    )}
                    {manageUserAccessModal && (
                        <ManageUserAccessModal
                            isOpen={manageUserAccessModal}
                            data={userToModifyObj}
                            toggleModal={toggleModal}
                            accounts={myAccounts}
                            editUserCallback={userCallbackActions.editUser}
                        />
                    )}
                    {editUserRoleModal && (
                        <EditUserRoleModal
                            isOpen={editUserRoleModal}
                            data={userToModifyObj}
                            toggleModal={toggleModal}
                            accounts={myAccounts}
                            editUserCallback={userCallbackActions.editUser}
                        />
                    )}
                    <FilterModal
                        filterName={'manageUserFilter'}
                        isOpen={filterModal}
                        toggleModal={()=>toggleModal('filter')}
                        renderFilterCriteria={(props)=>{
                            return <ManageUserFilterCriteria
                                key={'account-filter-criteria'}
                                {...props}
                                userList={companyUserList}
                            />
                        }}
                        filter={filter}
                        setFilter={(newFilter)=>this.setState(produce(newState=>{
                            newState.tableState.filter = newFilter
                        }))}
                    />
                </>
            )
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        filter: getSessionFilter('manageUserFilter') ?? state.identity?.preferences?.manageUserFilter,
        currentUser: state.auth.user,
        permissions: state.auth.user.permissions
    }
}

export default injectIntl(withRouter(connect(mapStateToProps)(ManageUserTable)));


