import React, { useState, useEffect, useRef }       from "react";
import { useLocation }                              from "react-router-dom";
import { useIntl }                                  from "react-intl";
import { useSelector }                              from "react-redux";

import UPSHeaderLoggedIn                            from '../UPSHeaderLoggedIn';
import UPSSideNav                                   from '../LeftSideNav';

export default function UPSHeaderSideNavWrapper() {
    const pathName = useLocation().pathname;
    const {locale} = useIntl();
    const { selectedCountry, selectedPaymentType } = useSelector(s => s.auth.user);
    const [isMenuOpen, setMenuStatus] = useState(false);
    const navRef = useRef(null);
    // we no longer can rely on <MDBSideNav> triggerOpening because resize events will close the menu
    // annoying experience due to android chrome address bar hiding/showing, as that triggers a resize event
    useEffect(() => {
        if (isMenuOpen) {
            // slide menu open, overflow-hidden on <body> prevents user from scrolling body content underneath overlay
            document.body.classList.add('overflow-hidden');
            navRef.current.sideNavRef.current.classList.add('side-slide-enter-done');
        } else {
            document.body.classList.remove('overflow-hidden');
            navRef.current.sideNavRef.current.classList.remove('side-slide-enter-done');
    }
    }, [isMenuOpen]);

    // close menu if path, language, country, or business unit changes
    useEffect(() => {
        if (isMenuOpen) {
            setMenuStatus(false);
        }
    }, [pathName, locale, selectedCountry, selectedPaymentType]);

    return (
        <>
            <UPSSideNav key={"main-nav"} navRef={navRef} isMenuOpen={!!isMenuOpen} onOverlayClick={() => setMenuStatus(false)} />
            <UPSHeaderLoggedIn key={"main-header-logged-in"} isMenuOpen={isMenuOpen} setMenuStatus={() => setMenuStatus(true)} />
        </>
    );
}