/*
 * Copyright (C) 2019 Paymentus Inc.
 *
 * All rights reserved.
 *
 * The source code in this file is confidential and is the sole property of
 * Paymentus Inc. Its use is restricted to those readers who are authorized by
 * the corporation. Any reverse engineering or reproduction or divulgence of the
 * content of this report without the written consent from Paymentus Inc. is
 * strictly prohibited.
 *
 * Created on
 * Created by
 *
 */

import {callApiDelete, callApiGet, callApiPost, fetchWrapper, getFullUrl, mapRole} from '../utils/utils';
import {isEmpty} from 'lodash';
import * as R from "ramda";

export function getIdentityAccountList(userId, apiUrlPrefix) {
    const headers = {
        'x-session-token': userId,
        'Content-Type': 'application/json',
    }

    return fetchWrapper(`${apiUrlPrefix}/identity/accounts`, 'GET', headers);
}

/**
 * POST /identity/savePreferences/${apiUrlPostfix} merges new preferences for a given identity
 *
 * apiUrlPostfix is one of payByPdf, payByText, notifications, tableSearchCriteria, tableColumns or tableFilter
 */
export function savePreferencesUpdate(preferences, apiUrlPrefix, apiUrlPostfix) {//
    const headers = {
        'Content-Type': 'application/json'
    };

    const body = {
        preferences: preferences
    };

    return fetchWrapper(`${apiUrlPrefix}/identity/savePreferences/${apiUrlPostfix}`, 'POST', headers, body);
}
export function checkIdentityExists(data, apiUrlPrefix, apiToken) {
    //get identity by external ID
    const headers = {'Content-Type': 'application/json'};
    const {loginId, email, csrTokens} = data;
    return fetchWrapper(`${apiUrlPrefix}/identity/exists`, 'POST', headers, {loginId, email, csrTokens});
}

const resToUserlist = R.map(user=>({...user, role: mapRole(user.role.code)}))

const callGetIdentityListByCompanyId = () => callApiGet({ url: getFullUrl(`/identity/identityList`) });

export const getIdentityListByCompanyId = R.compose
( R.andThen(resToUserlist)
    , callGetIdentityListByCompanyId
);

const resToDeletedUser = user => user // no parsing necessary currently

const callDeleteIdentity = ({id, externalId, loginId}) => callApiDelete({
    url: getFullUrl(`/identity/delete/${id}`),
    body: {externalId, loginId}
});

const callDeleteAdminIdentity = ({id, externalId, loginId}) => callApiDelete({
    url: getFullUrl(`/identity/deleteAdmin/${id}`),
    body: {externalId, loginId}
});

export const deleteIdentity = R.compose
( R.andThen(resToDeletedUser)
    , callDeleteIdentity
);

export const deleteAdminIdentity = R.compose
( R.andThen(resToDeletedUser)
    , callDeleteAdminIdentity
);

const resToUser = user=>({...user, role: mapRole(user.role.code)})

const parseUserBeforeCall = ({id, externalId, userName, email, role, firstName, lastName, addAccounts, removeAccounts, preferences})=>{
    let body = {id, externalId, loginId: userName, email, role: mapRole(role), firstName, lastName, externalModifiedOn: new Date(), preferences}
    if(addAccounts && !isEmpty(addAccounts)) body.addAccounts = addAccounts
    if(removeAccounts && !isEmpty(removeAccounts)) body.removeAccounts = removeAccounts
    return body;
}

const callUpdateIdentity = (user) => callApiPost({
    url: getFullUrl(`/identity/update`),
    body: parseUserBeforeCall(user)
});

const callUpdateAdminIdentity = (user) => callApiPost({
    url: getFullUrl(`/identity/updateAdmin`),
    body: parseUserBeforeCall(user)
});

export const updateIdentity = R.compose
( R.andThen(resToUser)
    , callUpdateIdentity
);

export const updateAdminIdentity = R.compose
( R.andThen(resToUser)
    , callUpdateAdminIdentity
);

const callCreateIdentity = (user) => callApiPost({
    url: getFullUrl(`/identity/create`),
    body: parseUserBeforeCall(user)
});

const callCreateAdminIdentity = (user) => callApiPost({
    url: getFullUrl(`/identity/createAdmin`),
    body: parseUserBeforeCall(user)
});

export const createIdentity = R.compose
( R.andThen(resToUser)
    , callCreateIdentity
);

export const createAdminIdentity = R.compose
( R.andThen(resToUser)
    , callCreateAdminIdentity
);

export function getAccessibleMerchants(apiUrlPrefix, apiToken) {
    const url = `${apiUrlPrefix}/identity/getAccessibleMerchants`,
        method = 'GET',
        headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-api-token-sub': apiToken
        };
        
    return fetchWrapper(url, method, headers);
}

export function getIdentityValidationAccountsList(apiUrlPrefix, apiToken) {
    const url = `${apiUrlPrefix}/identity/getIdentityValidationAccountsList`,
        method = 'GET',
        headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-api-token-sub': apiToken
        };
        
    return fetchWrapper(url, method, headers);
}