import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {MDBSideNav, MDBSideNavLink, MDBSideNavNav} from 'mdbreact';
import {MDBSideNavCat} from '../../MDBFix/MDBSideNavCat';
import {FormattedMessage, injectIntl} from 'react-intl';
import * as authActions from "../../../actions/auth-action";
import {setLastLogin} from "../../../utils/local-storage";
import { isDisputesEnabled,isFullInvoicingEnabled  } from '../../../utils/invoice-utils';
import UPSlogo from '../../../assets/images/logo/UPS_logo.svg'
import { sendGoogleAnalyticsEvent as ga, GA_CATEGORY } from "../../../utils/google-analytics-utils"
import UPSSideNavFooter from "../UPSSideNavFooter";
import {get as lodashGet} from 'lodash';
import CountrySettingsPanel from '../CountrySettingsPanel';
import supportLinks from "../../../constants/support-links";
import {isPBTSupportedCountry, isPBTPreferencesEnabled} from '../../../utils/payByText-utils.js';
import {isPBTConfigEnabled} from "../../../utils/config-utils";
import {routes} from "../../../routes";
import {UseEffect} from "../../ClassUseEffect/ClassUseEffect";

const R = require('ramda');
const path = require('path');

const hasAccounts = R.complement(R.isEmpty);
const hasStandAloneAccounts = (accounts=[], planEnrolled, businessUnit)=>{
    return businessUnit === 'EBS' && planEnrolled ? accounts.some(account => !account.isParent && !account.parentAccount) : hasAccounts(accounts)
}
const hasPlans = R.identity;
const hasAccountsOrPlans = (accounts, planEnrolled) => 
        R.or(hasAccounts(accounts), hasPlans(planEnrolled));

class LeftSideNav extends Component {
    constructor(props) {
        super(props);

        let showPayByTextAdminOption = false;
        let showPayByTextPref = false;
        if(isPBTSupportedCountry(props.auth.user?.selectedCountry || '', props.auth.user?.selectedPaymentType) ){
            if(isPBTPreferencesEnabled(props.identity)){
                showPayByTextAdminOption = true;
            }
            showPayByTextPref = true;
        }
        this.state = {
            breakWidth: 1440,
            windowWidth: 0,
            activeMenuItem: '',
            upsHomeUrl: '',
            adminOpen: false,
            preferencesOpen: false,
            showPayByTextAdminOption: showPayByTextAdminOption,
            showPayByTextPref: showPayByTextPref
        };
    }

    componentDidMount() {
        const { history, intl } = this.props;

        history.listen((location, action) => {
            //console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
            //console.log(`The last navigation action was ${action}`)
            //authActions.setLastLogin( new Date().getTime());
            if (location.pathname !== "/logout" && location.pathname !== "/auth/login") {
                setLastLogin(new Date().getTime()); // update the last login cookie with current time as user & sales demo still active
            }
        })

        this.getHomeUrl();
        // Add alt attribute in ups logo in left menu
        // Added here because no option provided in MDBSideNave for logo
        const upsLogoAltText = intl.formatMessage({id: 'ups.side-nav.ups-logo-alt-text'});
        document.querySelector(".logo-wrapper img")?.setAttribute("alt", upsLogoAltText);
    }

    componentDidUpdate(prevProps){
        const {auth, identity} = this.props;
        const currCountry = auth.user?.selectedCountry || '';
        const selectedPaymentType = auth.user?.selectedPaymentType || '';
        const prevCountry = prevProps.auth.user?.selectedCountry || '';
		let showPayByTextAdminOption = false;
		let showPayByTextPref = false;
		
        if(prevProps.auth.user !== this.props.auth.user || prevProps.auth.userInfo !== this.props.auth.userInfo || prevProps.settings.countryLocale !== this.props.settings.countryLocale){
            this.getHomeUrl();
        }
        if(currCountry != prevCountry || identity?.preferences?.payByTextSettings?.enabled != prevProps.identity?.preferences?.payByTextSettings?.enabled || selectedPaymentType != prevProps.auth.user?.selectedPaymentType){
            if(isPBTSupportedCountry(currCountry, auth.user?.selectedPaymentType)){
                showPayByTextPref = true;
				if(isPBTPreferencesEnabled(identity)){
                        showPayByTextAdminOption = true
                }
            }
			this.setState({
				showPayByTextAdminOption : showPayByTextAdminOption,
				showPayByTextPref : showPayByTextPref
			});
        }
    }

    getInvoiceLinkLabel = () => {
        const { planEnrolled, businessUnit } = this.props;

        if (businessUnit === 'EBS') {
            return planEnrolled ? 'ups.side-nav.accountInvoices' : 'ups.side-nav.invoice';
        } else if (businessUnit === 'FRT') {
            return 'ups.side-nav.my-pros';
        } else if (businessUnit === 'SCS') {
            return 'ups.side-nav.invoice';
        }
    };

    getPlanInvoiceLinkLabel = () => {
        const { planEnrolled, businessUnit } = this.props;

        if (businessUnit === 'EBS') {
            return 'ups.side-nav.plan';
        } else if (businessUnit === 'FRT' || businessUnit === 'SCS') {
            return 'ups.side-nav.my-statements';
        }
    };

    getHomeUrl(){
        const {auth, settings} = this.props;
        const {locale, selectedCountry} = auth.user || auth.userInfo || {};

        let language = locale ? locale.split("-")[0] : null;
        let country = selectedCountry ? selectedCountry.toLowerCase() : null;

        if(settings.countryLocale){
            let arr = settings.countryLocale.split("-");
            language = arr[0];
            country = arr[1].toLowerCase();
        }

        if(language && country){
            const {upsBaseUrl, upsHomeResource } = supportLinks;
            const homeUrl = upsBaseUrl + path.join(country, language, upsHomeResource);
            this.setState({
                upsHomeUrl : homeUrl
            });
        }
    }

    routeChangeAction = ()=>{
        const sideNavState = this.getRouteSideNavState(this.props.location.pathname)
        if(sideNavState) this.setState(state=>({...state, ...sideNavState}))
    }

    getRouteSideNavState = (pathname) => {
        return routes[1].subRoutes.find((route)=>(route.path.startsWith(pathname)))?.sideNavState
    }

    render() {
        const {pathname} = this.props.location;
        const {breakWidth, upsHomeUrl, showPayByTextAdminOption, showPayByTextPref, adminMenuOpen } = this.state;
        const {history, merchant, auth, authActions, layout, planEnrolled, businessUnit, intl, navRef, isMenuOpen, onOverlayClick, invoiceMetadata } = this.props;
        const {adminOpen, preferencesOpen} = this.state;
        if (!auth.user) {
            return null;
        }
        const { permissions = {}, accounts = [] } = auth.user;
        const clientConfigPBTEnabled = isPBTConfigEnabled(merchant);
        const showPbtAdminMenu = !!clientConfigPBTEnabled && showPayByTextAdminOption && ( permissions && permissions['otp_payment'] );
        const showPbtPref = !!clientConfigPBTEnabled && showPayByTextPref && ( permissions && permissions['otp_payment'] );
        let components = [];
        components.push(
            <MDBSideNavLink key={"ups-nav-dashboard"} exact to="/home" id="side-nav-link-dashboard"
                            className="side-nav-icon-dashboard mi" tabIndex={layout.modalOpen ? '-1' : '0'}
                            >
                <FormattedMessage id='ups.side-nav.dashboard'/>
            </MDBSideNavLink>
        );

        if (hasPlans(planEnrolled) || ['FRT', 'SCS'].includes(businessUnit)) {
            components.push(
                <MDBSideNavLink key={"ups-nav-plan"} exact to="/ups/billing/plan"
                                id="side-nav-link-plan-invoices" className="side-nav-icon-plan mi"
                                isActive={(match, location) => {
                                    if (location.pathname === '/ups/billing/invoice' && lodashGet(location, 'state.isDrillMode', false)) return true;
                                    return match;
                                }}
                                tabIndex={layout.modalOpen ? '-1' : '0'}>
                    <FormattedMessage id={this.getPlanInvoiceLinkLabel()} />
                </MDBSideNavLink>
            );
        }

        if (hasStandAloneAccounts(accounts, planEnrolled, businessUnit)) {
            components.push(
                <MDBSideNavLink key={`ups-nav-invoice`} exact to ="/ups/billing/invoice"
                                id="side-nav-link-my-invoices" className="side-nav-icon-invoice fa-custom fa-file-alt"
                                isActive={(match, location) => {
                                    if (location.pathname === '/ups/billing/invoice' && lodashGet(location, 'state.isDrillMode', false)) return false;
                                    return match;
                                }}
                                tabIndex={layout.modalOpen ? '-1' : '0'}>
                    <FormattedMessage id={this.getInvoiceLinkLabel()} />
                </MDBSideNavLink>
            );
        }

        if (hasAccountsOrPlans(accounts, planEnrolled) && (businessUnit === 'EBS' && isFullInvoicingEnabled(merchant)) ) {
            components.push(
                <MDBSideNavLink key={"ups-nav-advanced-search"} exact to="/ups/billing/search" id="side-nav-link-advanced-search"
                                className="side-nav-icon-invoice fa-custom fa-search" tabIndex={layout.modalOpen ? '-1' : '0'} >
                    <FormattedMessage id='ups.side-nav.advanced-search'/>
                </MDBSideNavLink>
            );
        }

        if (hasAccountsOrPlans(accounts, planEnrolled) && isDisputesEnabled(merchant, businessUnit, invoiceMetadata?.disableFRTDisputes)) {
            if (permissions['dispute_invoice']) {
                components.push(
                    <MDBSideNavLink key={"ups-nav-disputes"} exact to="/ups/billing/dispute" id="side-nav-link-disputes"
                                    className="side-nav-icon-disputes fa-custom fa-comment-dollar" tabIndex={layout.modalOpen ? '-1' : '0'}>
                        <FormattedMessage id='ups.side-nav.dispute'/>
                    </MDBSideNavLink>
                );
            }
        }

        permissions && permissions['payment_search'] && hasAccountsOrPlans(accounts, planEnrolled) && components.push(
            <MDBSideNavLink key="ups-nav-autopay" exact to="/autopay" id="side-nav-link-autopay"
                className="side-nav-icon-autopay mi" tabIndex={layout.modalOpen ? "-1" : "0"}
                isActive={(match, {pathname: path}) => {
                    if (path === "/autopay/create" || path === "/autopay/receipt")
                        return true;
                    return match;
                }}
            >
                <FormattedMessage id="ups.side-nav.autopay"/>
            </MDBSideNavLink>
        );

        permissions && permissions['payment_search'] && hasAccountsOrPlans(accounts, planEnrolled) && components.push(
            <MDBSideNavLink key={"ups-nav-payment-history"} exact to="/payments" id="side-nav-link-payment-history"
                            className="side-nav-icon-payment-history mi" tabIndex={layout.modalOpen ? '-1' : '0'}>
                <FormattedMessage id='ups.side-nav.payment-history'/>
            </MDBSideNavLink>
        );


        (permissions && (permissions['account_view'] || permissions['user_modify'])) && components.push(
            <MDBSideNavCat
                key={'admin-cat'}
                label={intl.formatMessage({id: 'ups.side-nav.admin'})}
                isOpen={adminOpen}
                toggleOpen={(e, open)=>this.setState({adminOpen:open??!adminOpen})}
                id="main-side-nav-link-admin" className="side-nav-icon-admin mi"
                tabIndex={layout.modalOpen ? '-1' : '0'}
            >
                {permissions['account_view'] && (
                    <MDBSideNavLink exact to={{pathname: "/admin/manage/accounts", state: {prevPath: pathname}}}
                        tabIndex={layout.modalOpen ? '-1' : '0'}
                        id="side-nav-link-manage-accounts">
                        <FormattedMessage id='ups.side-nav.admin.manage-accounts'/>
                    </MDBSideNavLink>
                )}
                {permissions['account_view'] && (businessUnit === 'EBS') && (
                    <MDBSideNavLink exact to={{pathname: "/admin/manage/plans", state: {prevPath: pathname}}}
                                tabIndex={layout.modalOpen ? '-1' : '0'}
                                id="side-nav-link-manage-plans">
                        <FormattedMessage id='ups.side-nav.admin.manage-plans'/>
                    </MDBSideNavLink>)}
                {permissions['user_modify'] && (
                    <MDBSideNavLink exact to={{pathname: "/ups/billing/admin/manage/users", state: {prevPath: pathname}}}
                        tabIndex={layout.modalOpen ? '-1' : '0'}
                        id="side-nav-link-manage-users"
                    >
                        <FormattedMessage id='ups.side-nav.admin.manage-users'/>
                    </MDBSideNavLink>
                )}
                {(showPbtAdminMenu && <MDBSideNavLink exact to={{pathname: "/admin/manage/paybytext", state: {prevPath: pathname}}}
                        tabIndex={layout.modalOpen ? '-1' : '0'}
                        id="side-nav-link-payByText"
                    >
                        <FormattedMessage id='ups.side-nav.admin.payByText'/>
                    </MDBSideNavLink>
                )}
            </MDBSideNavCat>
        );

        permissions && permissions['wallet_revision_history'] && components.push(
            <MDBSideNavLink key="ups-nav-wallet" exact to="/ups/billing/wallet" id="side-nav-link-wallet"
                            className="side-nav-icon-wallet mi" tabIndex={layout.modalOpen ? '-1' : '0'}>
                <FormattedMessage id='side-nav.wallet'/>
            </MDBSideNavLink>
        );

        components.push(
            <MDBSideNavCat key="ups-nav-profile" label={intl.formatMessage({id: 'side-nav.profile'})}
                           id="side-nav-link-profile" className="side-nav-icon-profile mi"
                           isOpen={preferencesOpen}
                           toggleOpen={(e, open)=>this.setState({preferencesOpen:open??!preferencesOpen})}
                           tabIndex={layout.modalOpen ? '-1' : '0'}>
                  
                
                    <MDBSideNavLink exact to={{pathname: "/ups/billing/profile", state: {prevPath: pathname}}}
                        tabIndex={layout.modalOpen ? '-1' : '0'}
                        id="side-nav-link-overview"
                    >
                        <FormattedMessage id='side-nav.dashboard-payments-overview'/>
                    </MDBSideNavLink>

               
                    <MDBSideNavLink exact to={{pathname: "/ups/billing/profile/notifications", state: {prevPath: pathname}}}
                                    tabIndex={layout.modalOpen ? '-1' : '0'}
                                    id="side-nav-link-notifications"
                    >
                        <FormattedMessage id='ups.preferences.notifications.header'/>

                    </MDBSideNavLink>
                    
                
                    <MDBSideNavLink exact to={{pathname: "/ups/billing/profile/pdf", state: {prevPath: pathname}}}
                        tabIndex={layout.modalOpen ? '-1' : '0'}
                        id="side-nav-link-pdf-invoice"
                    >
                        <FormattedMessage id='ups.preferences.pdfinvoice.title'/>
                    </MDBSideNavLink>

               
                    {( showPbtPref && <MDBSideNavLink exact to={{pathname: "/ups/billing/profile/paybytext", state: {prevPath: pathname}}}
                        tabIndex={layout.modalOpen ? '-1' : '0'}
                        id="side-nav-link-profile-payByText"
                    >
                        <FormattedMessage id='ups.title.payByText'/>
                    </MDBSideNavLink>
                    )}
                    
            </MDBSideNavCat>
        );

        components.push(
            <MDBSideNavLink key="ups-nav-support" exact to="/support" id="side-nav-link-support"
                            className="side-nav-icon-help-support fa-custom fa-user-headset" tabIndex={layout.modalOpen ? '-1' : '0'}>
                <FormattedMessage id='ups.side-nav.support'/>
            </MDBSideNavLink>
        );
        
        components.push(
            <MDBSideNavLink key="ups-nav-logout" to="/logout" id="side-nav-link-logout"
                            onClick={
                                () => {
                                    ga(GA_CATEGORY.NAVIGATION, 'Clicked Log Out')
                                    authActions.expireSessionCookie()
                                }
                            }
                            className="side-nav-icon-log-out mi" tabIndex={layout.modalOpen ? '-1' : '0'}>
                <FormattedMessage id='side-nav.log-out'/>
            </MDBSideNavLink>
        );

        return (
            <React.Fragment>
                <UseEffect observe={[pathname]} update={this.routeChangeAction}/>
                <MDBSideNav id={"main-nav"} fixed className={`main-nav ${!isMenuOpen ? 'hide-hamburger-menu' : ''}`} logo={UPSlogo} href={upsHomeUrl} ref={navRef}
                            breakWidth={breakWidth} mask="strong" showOverlay={false}>
                    <p className={"billing-center-title"}>
                        <FormattedMessage id="side-nav.billing-center.label"/>
                    </p>  

                    <CountrySettingsPanel key={"ups-country-setting-panel"}/>
                    
                    <MDBSideNavNav className={"main-nav-ul"} role={"navigation"}  aria-label={intl.formatMessage({id: "ups.main-menu.label"})}>
                        {components}
                    </MDBSideNavNav>
                    <UPSSideNavFooter key={"side-nav-footer"}/>

                    { auth.user.adUserId && (
                        <span className="main-nav-note">
                            <FormattedMessage id="impersonation-note" />
                        </span>
                    )}
                </MDBSideNav>
                {isMenuOpen && <div id={"sidenav-overlay"} onClick={() => onOverlayClick()}></div>}
            </React.Fragment>
        );
    }
}



function mapStateToProps(state, ownProps) {
    const {auth, settings, layout, identity, config, envProps} = state;
    
    return {
        auth,
        identity,
        settings,
        layout,
        planEnrolled: identity.planEnrolled,
        businessUnit: auth.user.selectedPaymentType,
        merchant: config?.sessionSelection?.merchant,
        invoiceMetadata: state.invoice.invoiceMetadata,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch)
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftSideNav)));