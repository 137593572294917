import React,  { Component } from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBRow, MDBCol, MDBCard } from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPortalModal from "../../../ReactPortalModal";
import produce from "immer";
import * as identityActions from "../../../../actions/identity-action";
import * as errorActions from "../../../../actions/error-action";
import UPSProgressBar from '../../../../components/UPS/ProgressBar';
import ManageUserAccessTable from '../../ManageUserAccessTable';
import UserDetails from '../../UserDetails';
import PlayBackTable from '../../PlayBackTable';
import * as validationActions from "../../../../actions/validation-action";
import attachValidator from "../../../../utils/validation/attach-validator";
import PageError from "../../../PageError";
import {getAccountUid} from "../../../../utils/ups-utils.js";
import {createAdminIdentity, createIdentity} from "../../../../api/identity-api";
import Spinner from "../../../Spinner";
import Error from "../../../Error";
import MDBBtnWrapper from '../../../MDBFix/MDBBtnWrapper';
const stepNames = [
    {key: 0, label: "ups.add-new-user.user-details.title", an_label: "user-details"},
    {key: 1, label: "ups.add-new-user.account-access.title", an_label: "account-access"},
    {key: 2, label: "ups.add-new-user.confirmation", an_label: "confirmation"}
];

class AddNewUserModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                userName: '',
                email: '',
                role: 'VIEW_ONLY'
            },
            modalStep: 1,
            loading:false,
            errors:[]
        };
        this.submitStep = this.submitStep.bind(this)
        this.createUser = this.createUser.bind(this)
        attachValidator.call(this)
    }

    handleInput = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        this.setState(
            produce(draft => {
                draft.user[inputName] = inputValue
            })
        );
    };

    handleOnBlur = e => {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        this.setState(
            produce(draft => {
                draft.user[inputName] = inputValue.trim()
            })
        );
    };

    gotoPrevious = () => {
        this.setState(
            produce(draft => {
                draft.modalStep--
            })
        );
        let scrollTarget = document.getElementById('add-new-user-modal');
        scrollTarget.scrollTop = 0;
    };

    gotoNext = () => {
        if (this.state.user.role === "ADMINISTRATOR") {
            this.setState(
                produce(draft => {
                    draft.modalStep = 3
                })
            );
        } else {
            this.setState(
                produce(draft => {
                    draft.modalStep++
                })
            );
        }

        let scrollTarget = document.getElementById('add-new-user-modal');
        scrollTarget.scrollTop = 0;
    };

    createUser(callback){
        let {user} = this.state
        let {accounts, addUserCallback, permissions, preferences} = this.props
        let fullUserData = {...user}
        let accountLookup = {}
        const parseAccounts = (selectedAccounts)=>selectedAccounts.forEach(account=>{
            let uid = getAccountUid(account)
            accountLookup[uid] = true
        })
        if(user.role !== "ADMINISTRATOR") parseAccounts(Object.values(this.getSelectedAccounts()))
        else parseAccounts(accounts) //set account list to my list of accounts if I'm creating an admin
        fullUserData.addAccounts = accountLookup
        fullUserData.preferences = preferences // new users will have the same preferences as the admin that created them
        this.setState({loading:true})
        const addUserCall = permissions['user_admin_modify'] ? createAdminIdentity : createIdentity
        //add new user
        addUserCall(fullUserData)
            .then((resultUser)=> {
                this.setState({playBackList: resultUser.accounts}, callback)
                addUserCallback(resultUser)
            })
            .catch(({errorCode})=>{
                if(errorCode) this.setState({errors: [errorCode]})
            })
            .finally(()=>{
                this.setState({loading:false})
            })
    }

    submitStep(step) {
        let result = this.validator.validateAll()
        if(result.messages.length > 0) return;
        if(step === 1){
            let {identityActions} = this.props;
            let {userName, email, role} = this.state.user
            identityActions.checkIdentityExists({loginId: userName, email}, (response)=>{
                if(response){
                    const {externalId, lastName, firstName} = response;
                    this.setState(produce(draft=>{
                        draft.user.firstName = firstName
                        draft.user.lastName = lastName
                        draft.user.externalId = externalId
                    }), ()=>{
                        if(role === 'ADMINISTRATOR') {
                            this.createUser(()=>this.gotoNext())
                        } else {
                            this.gotoNext()
                        }
                    })
                }
            })
        }
        if(step === 2){
            this.createUser(()=>this.gotoNext())
        }
    }

    render() {
        const { isOpen, toggleModal, backdrop, submitted, intl} = this.props;
        const { modalStep, user, loading, errors } = this.state;
        const modalHeading = "ups.add-new-user.title";

        if (isOpen) {
            return (
                <ReactPortalModal isOpen={isOpen} an_label={"add-new-user" + (stepNames[modalStep-1] ? `|${stepNames[modalStep-1].an_label}`: "")}>
                    <MDBModal id="add-new-user-modal" isOpen={isOpen} toggle={() => toggleModal('addNewUser')} size="xl" backdrop={backdrop} disableBackdrop={true} disableFocusTrap={false} labelledBy={intl.formatMessage({id:modalHeading})}>
                        <Spinner isSpinning={loading} spinnerText={'loading.spinner.long'}/>
                        <MDBModalHeader tag="h2" closeAriaLabel={intl.formatMessage({id:"close.dialog.btn"},{ name: intl.formatMessage({ id: modalHeading }) })} toggle={() => toggleModal('addNewUser')}><FormattedMessage id={modalHeading} /></MDBModalHeader>
                        <MDBModalBody>
                            <MDBCard className={"mb-4"}>
                                <UPSProgressBar key="progress-step-new-user" stepNames={stepNames} currentStep={modalStep-1} isShown={true}/>
                            </MDBCard>
                            {errors?.length > 0 && <MDBRow><Error errors={errors}/></MDBRow>}
                            {modalStep === 1 && (
                                <React.Fragment>
                                    {submitted && <PageError/>}
                                    <UserDetails
                                        key={"add-new-user-details"}
                                        user={user}
                                        editMode={true}
                                        handleInput={this.handleInput}
                                        handleOnBlur={this.handleOnBlur}
                                        titleMsgId={"ups.add-new-user.user-details.title"}
                                        textMsgId={"ups.add-new-user.user-details.instructions-1"}
                                    />
                                </React.Fragment>
                            )}
                            {modalStep === 2 && (
                                <React.Fragment>
                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            <h3 className={"mb-4"}><FormattedMessage id={"ups.add-new-user.account-access.title"} /></h3>
                                            <PlayBackTable key={"playback-new-user-ccount-access"} user={user} />
                                        </MDBCol>
                                    </MDBRow>
                                        <MDBRow>
                                            <MDBCol size={"12"}>
                                                <p className="ups-note-1 mt-0">*<span className="font-italic"><FormattedMessage id="ups.required-field.note" /></span></p>
                                            </MDBCol>
                                            <MDBCol size={"12"}>
                                                <div className={"datatable-blend-container"}>
                                                    <div className={"datatable-blend-inner"}>
                                                        <h3><FormattedMessage id={"ups.add-new-user.plans-and-accounts.title"} /></h3>
                                                        <p><FormattedMessage id={"ups.add-new-user.plans-and-accounts.instructions-1"}/></p>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <ManageUserAccessTable
                                                    caption= "ups.add-new-user.plans-and-accounts.title"
                                                    name={'add-new-user-account-list'}
                                                    accounts={this.props.accounts}
                                                    key="add-new-user-account-list"
                                                    source={"add-new-user-account-list"}
                                                    selectable={true}
                                                    registerGetSelection={(func)=>{
                                                        this.getSelectedAccounts = func
                                                    }}
                                                    validations={[
                                                        (selectedList)=>{
                                                            if(Object.keys(selectedList).length === 0) return "account.assignment.required"
                                                            else return ""
                                                        }
                                                    ]}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                </React.Fragment>
                            )}
                            {modalStep === 3 &&
                                <React.Fragment>
                                    <MDBRow>
                                        <MDBCol size={"12"}>
                                            <h3 className={"mb-4"}><FormattedMessage id={"ups.add-new-user.confirmation-title"} /></h3>
                                            <PlayBackTable key={"playback-new-user-added"} user={user} />
                                        </MDBCol>
                                    </MDBRow>
                                    {this.state.playBackList && (<MDBRow>
                                        <MDBCol size={"12"}>
                                            <h3 role="alert"><FormattedMessage id="ups.add-new-user.confirmation.plans-and-accounts.line1" /></h3>
                                            <ManageUserAccessTable
                                                caption= "ups.add-new-user.confirmation-title"
                                                accounts={this.state.playBackList}
                                                key="add-new-user-account-confirmation-list"
                                                source={"add-new-user-account-confirmation-list"}
                                                selectable={false}
                                            />
                                        </MDBCol>
                                    </MDBRow>)}
                                </React.Fragment>
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBRow>
                                <MDBCol size={"12"}>
                                    {(modalStep !== 3 && modalStep > 1) && <MDBBtnWrapper label={intl.formatMessage({id: "btn-back"})} color="secondary" onClick={this.gotoPrevious}></MDBBtnWrapper>}
                                    {(modalStep === 1) && <MDBBtnWrapper label={intl.formatMessage({id: "btn-next"})} color="primary"  onClick={()=>this.submitStep(modalStep)}></MDBBtnWrapper>}
                                    {(modalStep === 2) && <MDBBtnWrapper label={intl.formatMessage({id: "btn-update"})} color="primary"  onClick={()=>this.submitStep(modalStep)}></MDBBtnWrapper>}
                                    {(modalStep === 3) && <MDBBtnWrapper label={intl.formatMessage({id: "btn-close"})} color="primary" onClick={() => toggleModal('addNewUser')}></MDBBtnWrapper>}
                                </MDBCol>
                            </MDBRow>
                            {modalStep !== 3 && (
                                <MDBRow>
                                    <MDBCol size={"12"}>
                                    <MDBBtnWrapper label={intl.formatMessage({id: "btn-cancel"})} color="cancel" onClick={() => toggleModal('addNewUser')}></MDBBtnWrapper>
                                    </MDBCol>
                                </MDBRow>
                            )}
                        </MDBModalFooter>
                    </MDBModal>
                </ReactPortalModal>
            )
        } else {
            return null;
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        submitted: state.validation.submitted,
        //required for attaching validator
        vFields: state.validation.vFields,
        vState: state.validation.vState,
        permissions: state.auth.user.permissions,
        preferences: state.identity.preferences
    }
}


function mapDispatchToProps(dispatch) {
    return {
        identityActions: bindActionCreators(identityActions, dispatch),
        //required for attaching validator
        validationActions: bindActionCreators(validationActions, dispatch),
        errorActions: bindActionCreators(errorActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddNewUserModal));

